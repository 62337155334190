import styled from "styled-components";
import { motion, cubicBezier } from "framer-motion";
import HomeSignup from "./HomeSignup";
import { MEDIA_QUERY_SM } from "../../utilities/constants/mediaQueries";

const Container = styled(motion.div)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--color-gray-1) url("/img/flyout-bg.png") center center repeat;
  background-size: 150px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateX(100%) translateZ(0px);
  opacity: 0;
  justify-content: flex-start;

  @media (min-width: ${MEDIA_QUERY_SM}) {
    justify-content: center;
  }
`;

const CloseIcon = styled.button`
  background: url("/img/white-x.svg") center center no-repeat;
  background-size: 18px 18px;
  cursor: pointer;
  padding: 0.5rem;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 1px solid transparent;
  transition: border-color var(--animation-timing-quick) var(--timing-easeOutQuart);

  &:hover,
  &:focus {
    border-color: var(--color-gray-7);
    outline: 0;
  }
`;

const easing = cubicBezier(0.76, 0, 0.24, 1);

const variants = {
  open: { opacity: 1, y: 0 },
  closed: { opacity: 0, y: "-100%" },
};

export default function HomeFlyout({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
  return (
    <Container animate={isOpen ? "open" : "closed"} variants={variants} transition={{ ease: easing }}>
      <HomeSignup />
      <CloseIcon onClick={onClose} tabIndex={0} />
    </Container>
  );
}
