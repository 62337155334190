import styled from "styled-components";
import { MEDIA_QUERY_SM } from "../../utilities/constants/mediaQueries";
import { ReactNode } from "react";

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  border-radius: 9999px;
  font-family: var(--font-family-headline);
  font-weight: 400;
  font-size: 1rem;
  height: 3rem;
  min-width: 14rem;
  width: 7rem;
  color: var(--color-gray-1);
  background: var(--color-gray-7);
  z-index: 2;
  border: 2px solid var(--color-gray-1);
  box-shadow: 0px 0px 0px var(--color-gray-1), 0px 0px 12px rgba(0, 0, 0, 0.05);
  transform: translate(0px, 0px);
  transition: all var(--animation-timing-quick) var(--timing-easeOutQuart);

  &:hover {
    box-shadow: 4px 4px 0px var(--color-primary), 0px 0px 12px rgba(0, 0, 0, 0.05);
    transform: translate(-4px, -4px);
    transition: all var(--animation-timing-quick) var(--timing-easeOutQuart);
  }

  &:active {
    box-shadow: 0px 0px 0px #313131, 0px 0px 0px rgba(0, 0, 0, 0.05);
    transform: translate(0px, 0px);
  }

  @media (min-width: ${MEDIA_QUERY_SM}) {
    font-size: 1.5rem;
    height: 3.5rem;
    min-width: 20rem;
  }
`;

export default function HomeButton({
  children,
  onClick,
  className = "",
}: {
  className?: string;
  onClick?: () => void;
  children: ReactNode;
}) {
  return (
    <Button className={className} onClick={onClick}>
      {children}
    </Button>
  );
}
