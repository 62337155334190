import Image from "next/image";
import styled from "styled-components";
import {
  MEDIA_QUERY_LG,
  MEDIA_QUERY_MD,
  MEDIA_QUERY_SM,
  MEDIA_QUERY_XXXL,
} from "../../../utilities/constants/mediaQueries";
import { useAnalyticsContext } from "../../state/AnalyticsProvider";

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 2px solid var(--color-gray-1);
  z-index: 2;
  position: relative;
  background: var(--color-gray-7);
`;

const FooterUpper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 2px solid var(--color-gray-1);

  @media (min-width: ${MEDIA_QUERY_LG}) {
    flex-direction: row;
  }
`;

const Tagline = styled.div`
  font-family: var(--font-family-headline);
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  letter-spacing: 2.3px;
  align-self: center;

  @media (min-width: ${MEDIA_QUERY_SM}) {
    font-size: 1.125rem;
  }

  @media (min-width: ${MEDIA_QUERY_LG}) {
    padding: 0 8rem 0 4rem;
    border-right: 2px solid var(--color-gray-1);
    align-self: initial;
  }
`;

const SocialLinks = styled.div`
  font-family: var(--font-family-headline);
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding: 0 2rem 1rem;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: ${MEDIA_QUERY_LG}) {
    margin-right: 0;
    padding: 1rem 4rem 1rem 2rem;
  }

  & > span {
    font-size: 1.125rem;
    letter-spacing: 2.3px;
    display: none;

    @media (min-width: ${MEDIA_QUERY_LG}) {
      display: inline;
    }
  }
`;

const SocialIconLink = styled.a`
  padding: 0.25rem;
  width: 3rem;
  height: 3rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${MEDIA_QUERY_LG}) {
    width: 4rem;
    height: 4rem;
  }

  &:hover {
    filter: invert(6%) sepia(95%) saturate(7433%) hue-rotate(248deg) brightness(128%) contrast(146%);
  }
`;

const Footerlower = styled.div`
  display: flex;
  width: 100%;
  padding: 2rem;
  align-items: center;
  flex-direction: column;

  @media (min-width: ${MEDIA_QUERY_MD}) {
    flex-direction: row;
  }
`;

const FooterLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: 0 auto;
  font-size: 0.875rem;

  & > strong {
    font-weight: 600;
  }

  @media (min-width: ${MEDIA_QUERY_MD}) {
    align-items: flex-start;
    font-size: 1rem;
  }
`;

const FooterLink = styled.a.attrs({
  target: "_blank",
  rel: "noreferrer",
})`
  color: var(--color-gray-1);
  transition: color var(--animation-timing-quick) var(--timing-easeOutQuart);

  &:hover {
    color: var(--color-primary);
  }
`;

const FooterLogo = styled(Image)`
  width: 14vw;
  height: 14vw;

  @media (min-width: ${MEDIA_QUERY_XXXL}) {
    width: 12.5rem;
    height: 12.5rem;
  }
`;

const FooterHeader = styled.span`
  font-weight: 900;
  text-transform: uppercase;
  font-size: 10vw;

  @media (min-width: ${MEDIA_QUERY_XXXL}) {
    font-size: 11.25rem;
  }
`;

export default function HomeFooter() {
  const { pushTrackEvent } = useAnalyticsContext();

  return (
    <Footer>
      <FooterUpper>
        <Tagline>Where sports fans chat. &copy; {new Date().getFullYear()}</Tagline>
        <SocialLinks>
          <span>Meet us on</span>
          <SocialIconLink
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/onbunches/"
            onClick={() => pushTrackEvent("[Marketing] Tapped Instagram footer link")}
          >
            <Image src="/img/social/instagram.png" alt="Bunches on Instagram" width="40" height="40" />
          </SocialIconLink>
          <SocialIconLink
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/@onbunches"
            onClick={() => pushTrackEvent("[Marketing] Tapped Twitter footer link")}
          >
            <Image src="/img/social/twitter.png" alt="Bunches on Twitter" width="40" height="40" />
          </SocialIconLink>
          <SocialIconLink
            target="_blank"
            rel="noreferrer"
            href="https://youtube.com/@onbunches"
            onClick={() => pushTrackEvent("[Marketing] Tapped YouTube footer link")}
          >
            <Image src="/img/social/youtube.png" alt="Bunches on YouTube" width="54" height="54" />
          </SocialIconLink>
        </SocialLinks>
      </FooterUpper>
      <Footerlower>
        <FooterLogo src="/img/app_logo.png" alt="Bunches" width="160" height="160" />
        <FooterHeader>Bunches</FooterHeader>
        <FooterLinks>
          <FooterLink href="/privacy-policy">Privacy Policy</FooterLink>
          <FooterLink href="/disclaimer">Disclaimer</FooterLink>
          <FooterLink href="/terms-of-service">Terms of Service</FooterLink>
        </FooterLinks>
      </Footerlower>
    </Footer>
  );
}
