import Text from "../shared/Text";
import styled from "styled-components";
import { MEDIA_QUERY_MD, MEDIA_QUERY_SM, MEDIA_QUERY_XXXL } from "../../utilities/constants/mediaQueries";

export const ScoutText = styled(Text)`
  font-size: 1.25rem;

  @media (min-width: ${MEDIA_QUERY_MD}) {
    font-size: 1.5rem;
  }
`;

export const ScoutHeading = styled.h2<{ highlightColor?: string }>`
  font-family: var(--font-family-headline);
  font-size: 6.25rem;
  line-height: 0.8;
  -webkit-text-stroke: 2px var(--color-gray-1);
  color: var(--color-gray-1);

  & > span {
    color: ${({ highlightColor }) => highlightColor || `var(--color-gray-7)`};
  }

  @media (min-width: ${MEDIA_QUERY_SM}) {
    font-size: 16vw;
  }

  @media (min-width: ${MEDIA_QUERY_XXXL}) {
    font-size: 12.25rem;
  }
`;

export const ScoutSubheading = styled.h3`
  font-family: var(--font-family-headline);
  line-height: 1;
  color: var(--color-gray-1);

  font-size: 10vw;

  @media (min-width: ${MEDIA_QUERY_MD}) {
    font-size: 4rem;
  }
`;
