import Image from "next/image";
import styled from "styled-components";
import { MEDIA_QUERY_LG, MEDIA_QUERY_MD } from "../../utilities/constants/mediaQueries";
import { ScoutHeading } from "./ScoutText";

const Container = styled.div`
  background: var(--color-gray-1);
  padding: 1rem 0;
  margin-bottom: 0;
  z-index: 2;
  position: relative;
`;

const Inner = styled.div`
  max-width: 1280px;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-direction: column;

  @media (min-width: ${MEDIA_QUERY_MD}) {
    flex-direction: row;
    gap: 1.5rem;
  }
`;

const BannerHeading = styled(ScoutHeading)<{ hideOnSmallScreen?: boolean }>`
  display: ${({ hideOnSmallScreen }) => (hideOnSmallScreen ? "none" : "flex")};
  align-items: center;
  gap: 0.5rem;
  color: var(--color-gray-7);
  font-size: 3rem;
  -webkit-text-stroke: 1px var(--color-gray-7);
  font-weight: 600;

  & > span {
    color: var(--color-gray-1);
  }

  @media (min-width: ${MEDIA_QUERY_MD}) {
    display: flex;
  }

  @media (min-width: ${MEDIA_QUERY_LG}) {
    font-size: 3.5rem;
  }
`;

export default function FooterBanner() {
  return (
    <Container>
      <Inner>
        <BannerHeading>Fan together</BannerHeading>
        <BannerHeading>
          <span>On</span> iOS
          <Image src="/img/apple-logo-white.png" width={40} height={40} alt="" />
        </BannerHeading>
        <BannerHeading hideOnSmallScreen>+</BannerHeading>
        <BannerHeading>
          <span>On</span> Android
          <Image src="/img/android-logo-white.png" width={65} height={65} alt="" />
        </BannerHeading>
      </Inner>
    </Container>
  );
}
