import { ReactNode } from "react";
import styled from "styled-components";

const StyledText = styled.p<{ fontSize?: string; primary?: boolean; bold?: boolean }>`
  color: ${({ primary }) => (primary ? "var(--color-primary)" : "var(--color-gray-1)")};
  font-weight: ${({ bold }) => (bold ? "600" : "normal")};
  font-size: ${({ fontSize }) => fontSize || "1rem"};
  line-height: 1.4;
  margin: 0;
  word-break: break-word;
`;

export default function Text({
  children,
  fontSize,
  bold = false,
  primary = false,
  ...props
}: {
  children: ReactNode;
  fontSize?: string;
  primary?: boolean;
  bold?: boolean;
}) {
  return (
    <StyledText fontSize={fontSize} primary={primary} bold={bold} {...props}>
      {children}
    </StyledText>
  );
}
