import styled from "styled-components";
import Image from "next/image";
import {
  MEDIA_QUERY_LG,
  MEDIA_QUERY_MD,
  MEDIA_QUERY_SM,
  MEDIA_QUERY_XXL,
} from "../../utilities/constants/mediaQueries";
import Text from "../shared/Text";

const Upper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin: 2.5rem 0;
  width: 85%;
  justify-content: center;
  flex-direction: column;

  @media (min-width: ${MEDIA_QUERY_SM}) {
    flex-direction: row;
    margin: 5rem 0;
    gap: 2.5rem;
  }
`;

const TextContainer = styled.div`
  display: flex;
  gap: 1.5rem;
  border-top: 1px solid var(--color-gray-7);
  padding: 2rem 0;
  width: 85%;
  justify-content: center;
  flex-direction: column;

  @media (min-width: ${MEDIA_QUERY_MD}) {
    flex-direction: row;
    gap: 1rem;
    padding: 2.5rem 0;
  }
`;

const Heading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  font-family: var(--font-family-headline);
  font-weight: 700;
  color: var(--color-gray-7);
  font-size: 12vw;
  text-transform: uppercase;
  line-height: 0.8;

  @media (min-width: ${MEDIA_QUERY_LG}) {
    font-size: 14vw;
  }

  @media (min-width: ${MEDIA_QUERY_XXL}) {
    font-size: 12.25rem;
  }

  & > span {
    font-size: 1.5rem;
    text-shadow: 0 0 1rem var(--color-gray-1);
  }
`;

const QrCode = styled(Image)`
  width: 29vw;
  height: 29vw;
  background: var(--color-gray-7);
  border-radius: 0;

  @media (min-width: ${MEDIA_QUERY_MD}) {
    border-radius: 2rem;
  }

  @media (min-width: ${MEDIA_QUERY_XXL}) {
    width: 350px;
    height: 350px;
  }
`;

const LowerText = styled(Text)`
  font-family: var(--font-family-headline);
  font-weight: 500;
  font-size: 1.125rem;
  letter-spacing: 2.3px;
  color: var(--color-gray-7);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  & > span {
    position: relative;
    z-index: 11;
    text-align: center;
  }
`;

const DotBackground = styled.u`
  display: block;
  background-image: radial-gradient(var(--color-gray-7) 20%, var(--color-gray-1) 20%);
  background-position: 0 0;
  background-size: 12px 12px;
  height: 62px;
  width: 60%;
  opacity: 0.25;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
`;

export default function HomeSignup() {
  return (
    <>
      <Upper>
        <Heading>
          Download <span aria-hidden>Sports x Chat</span> the app
        </Heading>
        <QrCode src="/img/apple-qr-code-large.svg" alt="" width={358} height={358} />
      </Upper>
      <TextContainer>
        <LowerText>
          <span>Now available on iOS and Android</span> <DotBackground />
        </LowerText>
      </TextContainer>
    </>
  );
}
